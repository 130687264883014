<template>
	<div class="shopping-cart">
		<van-checkbox-group v-model="result" ref="checkboxGroup">

			<div v-for="(item, index) in cartList" class="goods-item">
				<van-swipe-cell style="height: 100%;width: 100%;">
					<van-checkbox :name="item" :ref="el => checkboxRefs[index] = el" @click.stop v-model="item.checked"
						@click="checked(item,index)" />
					<van-card :price="item.price" desc="描述信息" :title="item.name" :thumb="imageUrl + item.image"
						@click-thumb="goGoodsDetail(item.pid)">
						<template #footer>
							<van-stepper v-model="item.count" integer @plus="changeNum(item,1,index)"
								@minus="changeNum(item,-1,index)" disable-input/>
						</template>
					</van-card>
					<template #right>
						<van-button square text="删除" type="danger" class="delete-button" @click="deleteCart(item.id)"/>
					</template>
				</van-swipe-cell>
			</div>


		</van-checkbox-group>
		<van-empty class="custom-image" :image="emptyImg" description="暂无商品" v-if="emptyStatus" />
		<van-submit-bar v-if="!emptyStatus" :price="price * 100" button-text="去结算" @submit="goOrderConfirm">
			<van-checkbox v-model="isCheckAll" @click="checkAll">全选</van-checkbox>
		</van-submit-bar>
	</div>
</template>

<script>
	import {
		ref,
		onBeforeUpdate
	} from 'vue';
	import {
		getCartList,
		changeGoodsNum,
		deleteCart
	} from '@/api/shop'
	import {
		getUserAddressList
	} from '../../api/user.js'

	import {
		orderSubmit
	} from '../../api/order.js'
	import {
		Toast,
		Dialog
	} from "vant";
	
	export default {
		setup() {
			const onSubmit = () => Toast('点击按钮');
			const onClickLink = () => Toast('修改地址');
			// const checked = ref([]);
			const checkboxRefs = ref([]);
			const value = ref(1);
			const toggle = (index) => {
				checkboxRefs.value[index].toggle();
			};

			return {
				onSubmit,
				onClickLink,
				toggle,
				// checked,
				checkboxRefs,
				value,

			};
		},
 inject:['reload'],
		data() {
			return {
				cartList:'',
				imageUrl: this.url.imageUrl,
				list: ['a', 'b'],
				newDataArr: '',
				result: [],
				isCheckAll: false,
				defaultId: '',
				cartIdList: [],
				price: 0,
				emptyStatus: false,
				emptyImg: require('@/assets/zwsp.png'),
			}
		},

		mounted() {
			console.log(this.$route.query)
			this.$toast.loading({
				message: '加载中...',
				forbidClick: true,
			});
			Promise.all([this.getCartList(), this.getUserAddressList()]).then((res) => {
				// console.log(res)
				this.$toast.clear()

			}).catch((error) => {
				console.log(error)

			});

		},
		methods: {
			//删除购物车
			deleteCart(id){
				deleteCart({id:id}).then((res)=>{
				console.log(res)	
				if(res.code == 200){
					this.$toast('删除成功')
					this.reload()
				}
				})
				},
			goGoodsDetail(id) {
				this.$router.push({
					path: '/goodsDetail',
					query: {
						id: id
					}
				})
			},
			//修改商品数量
			changeNum(item, type,ins) {
				console.log(item)
				console.log(ins)
				// console.log(this.cartList[ins].count - 1)
				item.checked ? this.price += Number(item.price) * type : this.price
				let params = {}
				params.id = item.id
				params.count = item.count + type
				changeGoodsNum(params).then((res) => {
					console.log(res)
					if(res.code == 400){
						this.cartList[ins].count = item.count-1
						console.log(item.count-1)
					}else{
						console.log(res)
					}
					// this.getCartList()
				})
			},
			//去结算
			goOrderConfirm() {
				this.cartList.forEach((v, i) => {
					console.log(v.id)
					if (v.checked) {
						this.cartIdList.push(v.id)
					}
				})

				if (this.cartIdList.length > 0) {
					this.$router.push({
						path: '/orderConfirm',
						query: {
							orderDataList: this.cartIdList

						}
					})
				} else {
					this.$toast('请选择商品')
				}

			},
			//提交订单
			onSubmit() {
				let params = {
					cart_id: [],
					address_id: this.defaultId
				}
				this.cartList.forEach((v, i) => {
					if (v.checked) {
						this.cartIdList.push(v.id)
					}
				})

				params.cart_id.concat(this.cartIdList)
				orderSubmit(params).then((res) => {
					console.log(res)
				})
			},
			//获取地址列表
			async getUserAddressList() {
				await getUserAddressList().then((res) => {

					if (res.data) {
						res.data.forEach((v, i) => {
							if (v.isDefault) {
								this.defaultId = v.id
							}
						})
					}

				})
			},
			//选择商品
			checked(item, index) {
				console.log(item)
				let arr = [...this.cartList]
				let statusArr = []
				let totalNum = 0
				arr[index].checked = !arr[index].checked
				arr.forEach((v, i) => {
					statusArr.push(v.checked)
				})
				if (item.checked) {
					this.price += item.count * item.price
				} else {
					this.price -= item.count * item.price
				}
				var checkAllStatus = statusArr.every(function(value) {
					return value;
				});
				checkAllStatus ? this.isCheckAll = true : this.isCheckAll = false
			},
			//全选
			checkAll() {

				var checkStatus = this.isCheckAll
				let totalNum = 0
				this.cartList.forEach((v, i) => {
					v.checked = checkStatus
					totalNum += v.checked ? v.count * v.price : 0
				})
				this.price = totalNum
				this.$refs.checkboxGroup.toggleAll(checkStatus);
			},
			toggleAll() {
				this.$refs.checkboxGroup.toggleAll();
			},
			//获取购物车列表

			async getCartList() {
				let params = {}
				let newDataArr
				params.sid = this.$route.query.shopId
				await getCartList(params).then((res) => {
					console.log(res.data)
					if (res.data) {
						newDataArr = [...res.data]
						newDataArr.forEach((v, i) => {
							Object.defineProperty(v, "checked", {
								value: false,
								configurable: true,
								enumerable: true,
								writable: true
							});
						})
						this.cartList = newDataArr
						console.log(newDataArr)
					} else {
						this.emptyStatus = true
					}
				})


			},

		}
	};
</script>

<style scoped lang="less">
	.shopping-cart {
		padding-bottom: 50px;


		.goods-item {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 10px 15px;
			background-color: #FFFFFF;
			
.delete-button{
					height: 100% !important;
				
				}
			

			.van-card {
				width: 90%;
				margin-left: 10px;
			}
		}
		.goods-item::v-deep(.van-swipe-cell__right) {
			height: 100% !important;
		    top: 0 !important;
			display: flex;
			justify-content: center;
			align-items: center;
			
		}
	}
</style>
